import React from 'react';
import PropTypes from 'prop-types';

import colors from 'data/colors.json';
import Swatch from 'components/Swatch.js';


export default class Palette extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        extended: PropTypes.bool,
        swatches: PropTypes.array,
    };

    render() {
        let className = 'swatches';
        if(this.props.extended) {
            className += ' extended';
        }
        if(this.props.className) {
            className += ` ${this.props.className}`;
        }
        const palette = this.props.swatches ? this.props.swatches
            : Object.keys(colors);
        const primary = palette[0];
        return (
            <ol className={className}>
                {palette.map((color, index) =>
                    <React.Fragment key={index}>
                        <Swatch color={colors[color]}/>
                        {this.props.extended && colors[color].steps &&
                            colors[color].steps.map((step, index) =>
                                <Swatch color={step} key={index}/>
                            )
                        }
                    </React.Fragment>
                )}
                {this.props.extended && colors[primary].blacks &&
                    colors[primary].blacks.map((black, index) =>
                        <Swatch color={black} key={index}/>
                    )
                }
            </ol>
        );
    }
}
