import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/opt/build/repo/docs/src/components/Guide.js"
import Palette from 'components/Palette.js';

export default ({components, ...props}) => <MDXTag name="wrapper" Layout={DefaultLayout} layoutProps={props} components={components}>


<MDXTag name="p" components={components}>{`Each product takes one swatch from the primaries palette as its base tint and another as its complimentary colour. You can copy any swatch value to your clipboard by clicking it.`}</MDXTag>
<Palette
    className="hs"
    extended
    swatches={['teal','orange']}
/>
<Palette
    className="bc"
    extended
    swatches={['purple','yellow']}
/>
<Palette
    className="cm"
    extended
    swatches={['orange','blue']}
/>
<Palette
    className="sn"
    extended
    swatches={['red','blue']}
/>
<MDXTag name="h2" components={components}>{`Dynamic tinting`}</MDXTag>
<MDXTag name="p" components={components}>{`Mixing white into the primary and complimentary tints produces the extended palette. A dynamically generated neutral (tertiary) scale is the result of `}<MDXTag name="strong" components={components} parentName="p">{`mixing the primary tint into black.`}</MDXTag></MDXTag>
<div className="notice">
  The tertiary scale should only be used in software or when a product is being marketed in isolation. In cross-product marketing or any other situation, use true black (#000).
</div>

<MDXTag name="h2" components={components}>{`Primaries`}</MDXTag>
<MDXTag name="p" components={components}>{`HealthShare's base palette is bright and warm; contrasting typical branding of health organisations. We use a suite of eight vibrant primaries which are relatively equidistant from one another on the colour spectrum.`}</MDXTag>
<Palette/></MDXTag>

export const _frontmatter = {};

  