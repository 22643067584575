import React from 'react';
import PropTypes from 'prop-types';
import CopyToClipboard from 'react-copy-html-to-clipboard';


export default class Swatch extends React.Component {
    static propTypes = {
        color: PropTypes.object.isRequired,
    };

    convertToRgb(hex) {
        const shorthand = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

        hex = hex.replace(shorthand, (m, r, g, b) =>
            r + r + g + g + b + b
        );

        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
        } : null;
    }

    render() {
        const rgbArr = this.convertToRgb(this.props.color.hex);
        const rgb = `${rgbArr.r},${rgbArr.g},${rgbArr.b}`;
        const copyHint = 'Copy to clipboard';
        return (
            <li>
                <samp style={{backgroundColor: this.props.color.hex}}/>
                <dl>
                    <dt>Hex</dt>
                    <CopyToClipboard text={this.props.color.hex}>
                        <dd title={copyHint}>{this.props.color.hex}</dd>
                    </CopyToClipboard>
                    <dt>RGB</dt>
                    <CopyToClipboard text={rgb}>
                        <dd title={copyHint}>{rgb}</dd>
                    </CopyToClipboard>
                    {this.props.color.cmyk &&
                        <React.Fragment>
                            <dt>CMYK</dt>
                            <CopyToClipboard text={this.props.color.cmyk}>
                                <dd title={copyHint}>
                                    {this.props.color.cmyk}
                                </dd>
                            </CopyToClipboard>
                        </React.Fragment>
                    }
                    {this.props.color.pms &&
                        <React.Fragment>
                            <dt>PMS</dt>
                            <CopyToClipboard text={this.props.color.pms}>
                                <dd title={copyHint}>
                                    {this.props.color.pms}
                                </dd>
                            </CopyToClipboard>
                        </React.Fragment>
                    }
                </dl>
            </li>
        );
    }
}
